<!--
 * @Description: 客户移交详情明细新增
 * @Author: LiangYiNing
 * @Date: 2021-11-05 15:13:23
 * @LastEditTime: 2022-07-01 09:25:51
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="dialogTitle"
    :visible.sync="visible"
    width="70%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="loading"
    :element-loading-background="loadingBackground"
    custom-class="edit-customer-dialog"
  >
    <el-form
      ref="searchForm"
      label-width="80px"
      :model="searchForm"
      :rules="addRules"
    >
      <el-form-item class="search-item--2" label="客户名称" prop="corpNames">
        <input-plus
          v-model="searchForm.corpNames"
          valueType="String"
          placeholder="客户名称"
          title="客户名称"
          label="客户名称"
          clearable
        ></input-plus>
      </el-form-item>
      <el-form-item class="search-item--2">
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button class="reset" @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 start -->
    <section class="table-wrapper">
      <el-table
        border
        :height="400"
        :data="tableData"
        highlight-current-row
        class="elTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="corpName"
          label="客户名称"
          :min-width="100"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="arrearage"
          label="欠款"
          :min-width="100"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="salesmanName"
          label="业务员"
          :min-width="100"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="salesmanName"
          label="客户关键联系人"
          :min-width="120"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <e6-vr-select
              v-model="row.contactId"
              :data="row.contactList"
              :class="{
                'table-form': true,
                'el-form-item': true,
                'is-error': row.contactTest && !row.contactId
              }"
              placeholder="客户关键联系人"
              @change="contactIdChange(row)"
              @clear="contactIdChange(row)"
              title="客户关键联系人"
              :props="{
                id: 'contactId',
                label: 'contactName'
              }"
              clearable
            ></e6-vr-select>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="salesmanName"
          label="联系方式"
          :min-width="120"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <e6-vr-select
              v-model="row.phone"
              :data="row.phoneList"
              :class="{
                'table-form': true,
                'el-form-item': true,
                'is-error': row.phoneTest && !row.phone
              }"
              placeholder="需要先选联系人"
              title="需要先选联系人"
              clearable
            ></e6-vr-select>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <!-- 表格 end -->
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { getCustomerListBySalesman } from "@/api";
import inputPlus from "@/components/inputPlus";

export default {
  name: "selectCustomerDialog",
  components: { inputPlus },
  mixins: [base, listPage, listPageReszie],
  data() {
    return {
      visible: false,
      dialogTitle: "新增转移明细",
      searchForm: {
        salesman: "", //业务员
        corpNames: "", // 客户名称
        pageIndex: 1,
        pageSize: 10
      },
      selColumnId: [], // 多选数据
      tableData: [], // 表格数据
      total: 0,
      addRules: {}
    };
  },
  created() {},
  methods: {
    init(salesman) {
      this.visible = true;
      this.searchForm.salesman = salesman;
      if (this.searchForm.salesman) {
        this.queryList();
      }
    },
    async queryList() {
      try {
        this.loading = true;
        let res = await getCustomerListBySalesman(this.searchForm);
        let data = this.getFreezeResponse(res, {
          path: "data.array"
        });
        data = JSON.parse(JSON.stringify(data));
        // 洗一下数据 保证getter setter
        data.map(item => {
          item.contactId = "";
          item.contactTest = false;
          item.contactName = "";
          item.phone = "";
          item.phoneTest = false;
          item.phoneList = [];
        });
        this.tableData = data;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 表格多选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item;
      });
    },
    // 客户联系人选择
    contactIdChange(row) {
      if (!row.contactId) {
        row.phoneList = [];
        row.phone = "";
        return;
      }
      let target = row.contactList.find(
        item => item.contactId === row.contactId
      );
      if (target) {
        let phoneList = target.phoneList.map(item => {
          return {
            id: item,
            label: item
          };
        });
        row.phoneList = phoneList;
        row.contactName = target.contactName;
      }
    },
    handleClose() {
      this.visible = false;
      this.tableData = [];
      this.selColumnId = [];
      this.total = 0;
    },
    handleSubmit() {
      if (this.selColumnId.length === 0) {
        this.$message.warning("未选择数据");
        return;
      }
      if (!this.testSelectedData()) {
        this.$message.warning("所选数据未填写完成，请检查");
        return;
      }
      console.log(this.selColumnId);
      this.$emit("change", this.selColumnId);
      this.handleClose();
    },
    // 校验所选数据
    testSelectedData() {
      let test = true;
      this.selColumnId.map(item => {
        if (!item.contactId || !item.phone) {
          test = false;
          item.contactTest = !item.contactId;
          item.phoneTest = !item.phone;
        }
      });
      return test;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 300px;
    }
  }
}
.table-form.el-form-item {
  margin: 0;
  padding: 0;
}
</style>
